import { GET_PROJECT_MANAGERS_SUCCESS, GET_PROJECT_MANAGERS_ERROR, GET_PROJECT_MANAGERS_LOADING, ADD_PROJECT_MANAGER_SUCCESS, ADD_PROJECT_MANAGER_ERROR, ADD_PROJECT_MANAGER_LOADING, GET_DEPARTMENTS_SUCCESS, GET_DEPARTMENTS_ERROR, GET_DEPARTMENTS_LOADING, IS_TEAM_LEAD_SUCCESS, IS_TEAM_LEAD_LOADING, IS_TEAM_LEAD_ERROR, GET_DEPARTMENT_HEAD_LOADING, GET_DEPARTMENT_HEAD_ERROR, GET_DEPARTMENT_HEAD_SUCCESS, ADD_DEPARTMENT_HEAD_LOADING, ADD_DEPARTMENT_HEAD_ERROR, ADD_DEPARTMENT_HEAD_SUCCESS, GET_TEAM_LEADS_LOADING, GET_TEAM_LEADS_ERROR, GET_TEAM_LEADS_SUCCESS, ADD_TEAM_LEAD_LOADING, ADD_TEAM_LEAD_ERROR, ADD_TEAM_LEAD_SUCCESS, GET_ADMIN_USERS_SUCCESS, GET_ADMIN_USERS_ERROR, GET_ADMIN_USERS_LOADING, ADD_ADMIN_SUCCESS, ADD_ADMIN_ERROR, ADD_ADMIN_LOADING, DELETE_ADMIN_SUCCESS, DELETE_ADMIN_ERROR, DELETE_ADMIN_LOADING, DELETE_DEPARTMENT_HEAD_SUCCESS, DELETE_DEPARTMENT_HEAD_ERROR, DELETE_DEPARTMENT_HEAD_LOADING, DELETE_PROJECT_MANAGER_SUCCESS, DELETE_PROJECT_MANAGER_ERROR, DELETE_PROJECT_MANAGER_LOADING, DELETE_TEAM_LEAD_SUCCESS, DELETE_TEAM_LEAD_LOADING, DELETE_TEAM_LEAD_ERROR, GET_ROLE_SUCCESS, GET_ROLE_ERROR, GET_ROLE_LOADING , GET_USER_DEPARTMENTS_SUCCESS , GET_USER_DEPARTMENTS_ERROR , GET_USER_DEPARTMENTS_LOADING} from "../types";
import { GET_PROJECT_MANAGERS_URL, GET_ROLE_URL, ADD_PROJECT_MANAGERS_URL, IS_TEAM_LEAD, GET_DEPARTMENT_HEAD_URL, ADD_DEPARTMENT_HEAD_URL, GET_TEAM_LEADS_URL, ADD_TEAM_LEAD_URL, GET_ADMINS_URL, ADD_ADMIN_URL, DELETE_ADMIN_URL, DELETE_DEPARTMENT_HEAD_URL, DELETE_PROJECT_MANAGER_URL, DELETE_TEAM_LEAD_URL } from "../../constant"
import AuthContext from '../../services/Auth'
import axios from "axios"

export const getDepartment = (departmentName) => {
    return dispatch => {
        getDepartmentLoading(dispatch, true);
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            axios.get("https://graph.microsoft.com/v1.0/users?$select=department&$top=999&$filter=department ge '!'", { headers: { "Authorization": "Bearer " + token } })
                .then((data) => {
                    const filteredArr = data.data.value.reduce((acc, current) => {
                        const x = acc.find(item => item.department === current.department);
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, []);
                    getDepartmentSuccess(dispatch, filteredArr);
                }).catch(err => {
                    getDepartmentError(dispatch, err);
                });
        })
    }
}

export const getUserDepartment = (userId) => {
    return dispatch => {
        getUserDepartmentLoading(dispatch, true);
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            axios.get(`https://graph.microsoft.com/v1.0/users/${userId}?$select=onPremisesExtensionAttributes`, { headers: { "Authorization": "Bearer " + token } })
                .then((data) => {
                    const department = data.data.onPremisesExtensionAttributes.extensionAttribute5
                    getUserDepartmentSuccess(dispatch, department);
                }).catch(err => {
                    getUserDepartmentError(dispatch, err);
                });
        })
    }
}

const getUserDepartmentSuccess = (dispatch, data) => dispatch({ type: GET_USER_DEPARTMENTS_SUCCESS, data })
const getUserDepartmentError = (dispatch, error) => dispatch({ type: GET_USER_DEPARTMENTS_ERROR, error })
const getUserDepartmentLoading = (dispatch, loading) => dispatch({ type: GET_USER_DEPARTMENTS_LOADING, loading })

export const getMyDepartment = (userId) => {
    return dispatch => {
        getDepartmentLoading(dispatch, true);
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            axios.get(`https://graph.microsoft.com/v1.0/users?$select=department&$top=999&$filter=id eq '${userId}'`, { headers: { "Authorization": "Bearer " + token } })
                .then((data) => {
                    const filteredArr = data.data.value.reduce((acc, current) => {
                        const x = acc.find(item => item.department === current.department);
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, []);
                    getDepartmentSuccess(dispatch, filteredArr);
                }).catch(err => {
                    getDepartmentError(dispatch, err);
                });
        })
    }
}



export const getDepartmentFollowers = (departmentName) => {
    return dispatch => {
        getDepartmentLoading(dispatch, true);
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            axios.get("https://graph.microsoft.com/v1.0/me/directReports?$select=department&$top=999&$filter=department ge '!'", { headers: { "Authorization": "Bearer " + token } })
                .then((data) => {
                    const filteredArr = data.data.value.reduce((acc, current) => {
                        const x = acc.find(item => item.department === current.department);
                        if (!x) {
                            return acc.concat([current]);
                        } else {
                            return acc;
                        }
                    }, []);
                    getDepartmentSuccess(dispatch, filteredArr);
                }).catch(err => {
                    getDepartmentError(dispatch, err);
                });
        })
    }
}

const getDepartmentSuccess = (dispatch, data) => dispatch({ type: GET_DEPARTMENTS_SUCCESS, data })
const getDepartmentError = (dispatch, error) => dispatch({ type: GET_DEPARTMENTS_ERROR, error })
const getDepartmentLoading = (dispatch, loading) => dispatch({ type: GET_DEPARTMENTS_LOADING, loading })

export const getProjectManager = () => {
    return dispatch => {
        getProjectManagerLoading(dispatch, true);
        axios.get(`${GET_PROJECT_MANAGERS_URL}`)
            .then((response) => {
                getProjectManagerSuccess(dispatch, response.data)
            }).catch(err => {
                getProjectManagerError(dispatch, err);
            })
    }
}

const getProjectManagerSuccess = (dispatch, data) => { dispatch({ type: GET_PROJECT_MANAGERS_SUCCESS, data }) }
const getProjectManagerError = (dispatch, error) => dispatch({ type: GET_PROJECT_MANAGERS_ERROR, error })
const getProjectManagerLoading = (dispatch, loading) => dispatch({ type: GET_PROJECT_MANAGERS_LOADING, loading })

export const addProjectManager = (userEmail, userId, userName, teamId) => dispatch => {
    addProjectManagerLoading(dispatch, true);
    axios.post(ADD_PROJECT_MANAGERS_URL, JSON.stringify({ userEmail, userId, userName, teamId }))
        .then((response) => {
            addProjectManagerSuccess(dispatch, { userEmail, userId, userName, id: response.data })
        }).catch(err => {
            addProjectManagerError(dispatch, err)
        })
}

const addProjectManagerSuccess = (dispatch, data) => dispatch({ type: ADD_PROJECT_MANAGER_SUCCESS, data })
const addProjectManagerError = (dispatch, error) => dispatch({ type: ADD_PROJECT_MANAGER_ERROR, error })
const addProjectManagerLoading = (dispatch, loading) => dispatch({ type: ADD_PROJECT_MANAGER_LOADING, loading })

export const deleteProjectManager = (id) => dispatch => {
    deleteProjectManagerLoading(dispatch, true);
    axios.delete(`${DELETE_PROJECT_MANAGER_URL}&Id=${id}`)
        .then(response => {
            deleteProjectManagerSuccess(dispatch, id);
        }).catch(error => {
            deleteProjectManagerError(dispatch, error)
        })
}

const deleteProjectManagerSuccess = (dispatch, data) => dispatch({ type: DELETE_PROJECT_MANAGER_SUCCESS, data })
const deleteProjectManagerError = (dispatch, error) => dispatch({ type: DELETE_PROJECT_MANAGER_ERROR, error })
const deleteProjectManagerLoading = (dispatch, loading) => dispatch({ type: DELETE_PROJECT_MANAGER_LOADING, loading })

export const getDepartmentHeads = () => dispatch => {
    getDepartmentHeadsLoading(dispatch, true);
    axios.get(GET_DEPARTMENT_HEAD_URL)
        .then(response => {
            getDepartmentHeadsSuccess(dispatch, response.data);
        }).catch(err => {
            getDepartmentHeadsError(dispatch, err)
        })
}

const getDepartmentHeadsLoading = (dispatch, loading) => dispatch({ type: GET_DEPARTMENT_HEAD_LOADING, loading })
const getDepartmentHeadsSuccess = (dispatch, data) => dispatch({ type: GET_DEPARTMENT_HEAD_SUCCESS, data })
const getDepartmentHeadsError = (dispatch, error) => dispatch({ type: GET_DEPARTMENT_HEAD_ERROR, error })

export const addDepartmentHead = (userEmail, userId, userName, teamId, department) => dispatch => {
    addDepartmentHeadLoading(dispatch, true);
    axios.post(ADD_DEPARTMENT_HEAD_URL, JSON.stringify({ userEmail, userId, userName, teamId, department }))
        .then(response => {
            addDepartmentHeadSuccess(dispatch, { userEmail, userId, userName, teamId, department })
        }).catch(err => {
            addDepartmentHeadError(dispatch, err)
        })
}

const addDepartmentHeadLoading = (dispatch, loading) => dispatch({ type: ADD_DEPARTMENT_HEAD_LOADING, loading })
const addDepartmentHeadError = (dispatch, error) => dispatch({ type: ADD_DEPARTMENT_HEAD_ERROR, error })
const addDepartmentHeadSuccess = (dispatch, data) => dispatch({ type: ADD_DEPARTMENT_HEAD_SUCCESS, data })

export const getTeamLeads = () => dispatch => {
    getTeamLeadsLoading(dispatch, true);
    axios.get(GET_TEAM_LEADS_URL)
        .then(response => {
            getTeamLeadsSuccess(dispatch, response.data);
        }).catch(err => {
            getTeamLeadsError(dispatch, err)
        })
}

const getTeamLeadsLoading = (dispatch, loading) => dispatch({ type: GET_TEAM_LEADS_LOADING, loading })
const getTeamLeadsSuccess = (dispatch, data) => dispatch({ type: GET_TEAM_LEADS_SUCCESS, data })
const getTeamLeadsError = (dispatch, error) => dispatch({ type: GET_TEAM_LEADS_ERROR, error })

export const addTeamLead = (userEmail, userId, userName, teamId, department, teamName) => dispatch => {
    addTeamLeadLoading(dispatch, true);
    axios.post(ADD_TEAM_LEAD_URL, JSON.stringify({ userEmail, userId, userName, teamId, department }))
        .then(response => {
            var data = response.data;
            data.team = teamName;
            data.department = department;
            addTeamLeadSuccess(dispatch, data);
        }).catch(err => {
            addTeamLeadError(dispatch, err)
        })
}

const addTeamLeadLoading = (dispatch, loading) => dispatch({ type: ADD_TEAM_LEAD_LOADING, loading })
const addTeamLeadSuccess = (dispatch, data) => dispatch({ type: ADD_TEAM_LEAD_SUCCESS, data })
const addTeamLeadError = (dispatch, error) => dispatch({ type: ADD_TEAM_LEAD_ERROR, error })

export const deleteTeamLead = (id) => dispatch => {
    deleteTeamLeadLoading(dispatch, true);
    axios.delete(DELETE_TEAM_LEAD_URL + "&Id=" + id)
        .then(response => {
            deleteTeamLeadSuccess(dispatch, id);
        }).catch(error => {
            deleteTeamLeadError(dispatch, error)
        })
}

const deleteTeamLeadSuccess = (dispatch, data) => dispatch({ type: DELETE_TEAM_LEAD_SUCCESS, data })
const deleteTeamLeadError = (dispatch, error) => dispatch({ type: DELETE_TEAM_LEAD_ERROR, error })
const deleteTeamLeadLoading = (dispatch, loading) => dispatch({ type: DELETE_TEAM_LEAD_LOADING, loading })

export const getAdmin = () => dispatch => {
    getAdminLoading(dispatch, true);
    axios.get(GET_ADMINS_URL)
        .then(response => {
            getAdminSuccess(dispatch, response.data);
        }).catch(error => {
            getAdminError(dispatch, error);
        })
}

const getAdminLoading = (dispatch, loading) => dispatch({ type: GET_ADMIN_USERS_LOADING, loading })
const getAdminSuccess = (dispatch, data) => dispatch({ type: GET_ADMIN_USERS_SUCCESS, data })
const getAdminError = (dispatch, error) => dispatch({ type: GET_ADMIN_USERS_ERROR, error })

export const addAdmin = (userEmail, userId, userName, teamId) => dispatch => {
    addAdminLoading(dispatch, true);
    axios.post(ADD_ADMIN_URL, JSON.stringify({ userEmail, userId, userName, teamId }))
        .then(response => {
            addAdminSuccess(dispatch, { userEmail, userId, userName, teamId });
        }).catch(err => {
            addAdminError(dispatch, err);
        })
}

const addAdminLoading = (dispatch, loading) => dispatch({ type: ADD_ADMIN_LOADING, loading })
const addAdminSuccess = (dispatch, data) => dispatch({ type: ADD_ADMIN_SUCCESS, data })
const addAdminError = (dispatch, error) => dispatch({ type: ADD_ADMIN_ERROR, error })


export const deleteAdminUser = (id) => dispatch => {
    deleteAdminUserLoading(dispatch, true);
    axios.delete(`${DELETE_ADMIN_URL}`, {
        params: { "id": id }
    })
        .then(data => {
            deleteAdminUserSuccess(dispatch, id);
        }).catch(error => {
            deleteAdminUserError(dispatch, error);
        })
}

const deleteAdminUserSuccess = (dispatch, data) => dispatch({ type: DELETE_ADMIN_SUCCESS, data });
const deleteAdminUserError = (dispatch, error) => dispatch({ type: DELETE_ADMIN_ERROR, error });
const deleteAdminUserLoading = (dispatch, loading) => dispatch({ type: DELETE_ADMIN_LOADING, loading });


export const deleteDepartmentHead = (id) => dispatch => {
    deleteDepartmentHeadLoading(dispatch, true);
    axios.delete(`${DELETE_DEPARTMENT_HEAD_URL}&id=${id}`)
        .then(data => {
            deleteDepartmentHeadSuccess(dispatch, data);
        }).catch(error => {
            deleteDepartmentHeadError(dispatch, error)
        })
}

const deleteDepartmentHeadSuccess = (dispatch, data) => dispatch({ type: DELETE_DEPARTMENT_HEAD_SUCCESS, data })
const deleteDepartmentHeadError = (dispatch, error) => dispatch({ type: DELETE_DEPARTMENT_HEAD_ERROR, error })
const deleteDepartmentHeadLoading = (dispatch, loading) => dispatch({ type: DELETE_DEPARTMENT_HEAD_LOADING, loading })

