import { GET_DEFAULT_PROJECTS_SUCCESS, GET_TASKS, TASK_UPDATE_TIME, EVENTS_TIME_ADD } from "../types"

const taskReducers = (state = {}, actions) => {
    switch (actions.type) {
        case GET_TASKS:
            return { ...state, projects: actions.payload };
        case TASK_UPDATE_TIME:
            var projectList = state.projects;
            var projectShouldChange = state.projects[actions.payload.index.index];
            var timeChange = actions.payload.time - projectShouldChange.time;
            projectShouldChange.time = actions.payload.time;
            projectShouldChange.currentHours = projectShouldChange.currentHours + timeChange;
            projectShouldChange.userCurrentTime = projectShouldChange.userCurrentTime + timeChange;
            projectShouldChange.userTime = projectShouldChange.userTime + timeChange;
            projectShouldChange.userProjectId = actions.payload.index.id;
            projectList[actions.payload.index.index] = projectShouldChange;
            return { ...state, projects: projectList };
        case GET_DEFAULT_PROJECTS_SUCCESS:
            return { ...state, events: actions.payload }
        case EVENTS_TIME_ADD:
            var eventList = state.events;
            eventList[actions.payload.index].time = actions.payload.data.time;
            eventList[actions.payload.index].projectAssignId = actions.payload.data.id;
            return { ...state, events: eventList }
        default:
            return state
    }
}

export default taskReducers;