import React from 'react';
import PropTypes from 'prop-types';
import "../../assets/css/alert.scss"

const Alert = ({ alertMessage, close }) => {
    return (
        <div className="alert-contain" style={{ width: window.innerWidth - 300 }} >
            <div className="alert-main">
                <div className="alert-bar">
                </div>
                <div className="alert-text-main">
                    <p className="alert-text"><span className="alert-icon">!</span></p>
                    <div className="alert-container">
                        <h6 className="alert-text">{alertMessage}</h6>
                    </div>
                </div>
                <div className="alert-close" onClick={() => close()}>
                    <h5 className="alert-closeText">X</h5>
                </div>
            </div>
        </div>
    );
};



export default Alert;