import React from 'react';
import ReactDOM from 'react-dom';
import "./assets/css/bootstrap.min.css"
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AdalConfig from './config/AdalConfig'
import AuthContext from './services/Auth'
import axios from 'axios';
import { subscribeKey } from './constant';

const width = window.innerWidth
// Extra callback logic, only in the actual application, not in iframes in the app
if ((window === window.parent) && window === window.top && !AuthContext.isCallback(window.location.hash)) {
  // Having both of these checks is to prevent having a token in localstorage, but no user.

  if (!AuthContext.getCachedToken(AdalConfig.clientId) || !AuthContext.getCachedUser()) {
    AuthContext.login()
    // or render something that everyone can see
    // ReactDOM.render(<PublicPartOfApp />, document.getElementById('root'))
  } else {
    //console.log(AuthContext.getCachedUser())
    //console.log(AdalConfig.clientId);
    AuthContext.acquireToken(AdalConfig.clientId, (message, token, msg) => {
     // console.log(token);
      if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        // axios.defaults.headers.common['Ocp-Apim-Subscription-Key'] = subscribeKey;
        // axios.defaults.headers.common['Access-Control-Allow-Origin'] = 'http://localhost:3000'
        ReactDOM.render(<App />, document.getElementById('root'))
      }else{
        console.error("Please check Tenant details")
      }
    })
  }
}
AuthContext.handleWindowCallback()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
