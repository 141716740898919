import React, { Component } from 'react';
import './App.css';
import { Router, Switch, Route } from "react-router-dom";
import Home from "./store/components/Home"
import Projects from "./store/components/Projects/Projects"
import ActiveProjects from "./store/components/Projects/ActiveProjects"
import CompletedProjects from "./store/components/Projects/CompletedProjects"
import NonBoundaryActivity from "./store/components/NonBoundaryActivity"
import AddProject from "./store/components/Projects/AddProject"

import EditProject from "./store/components/Projects/EditProject"
import HistoryProject from "./store/components/Projects/HistoryProject"
import TodayProjects from "./store/components/Projects/TodayProjects"
import Users from "./store/components/Users/Users"
import Upload from "./store/components/Users/Upload"
import UsersAssignDetails from "./store/components/Users/UsersAssignDetails"
import EditAssign from "./store/components/Users/EditAssign"
import HistoryUser from "./store/components/Users/HistoryUser"
import UsersAssign from "./store/components/Users/UsersAssign"
import Role from "./store/components/Role"
import Team from "./store/components/Team"
import { Provider } from 'react-redux'
import "./assets/css/style.scss"
import Nav from "./containers/Navbar/Navbar"
import NavbarTop from "./containers/Navbar/NavbarTop"
import Activity from "./store/components/Activity/Activity"
import Tasks from "./store/components/Tasks/Tasks"
import Report from "./store/components/Report"
import TeamLeadReport from "./store/components/Report/TeamReports"
import histroy from './history'
import { Layout, Menu } from 'antd';
import 'antd/dist/antd.min.css';
import Navigation from './containers/Navbar/Navigation';
import store from "./store/index"
import AddSupport from './store/components/Support/AddSupport';
import AssignUsersSupport from './store/components/Support/AssignUsersSupport';
import Support from './store/components/Support';
import HistorySupport from './store/components/Support/HistorySupport';
import UploadSalesStage from "./store/components/Users/UploadSalesStage";
import UploadProjects from "./store/components/Projects/UploadProjects";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navSide: false
    }
  }

  render() {
    return (
      <Provider store={store}>
        <Router history={histroy}>
          <Layout>
            {/* <Header className="header" style={{ position: "fixed", zIndex: "2000" }}> */}
            <NavbarTop header="Home" />
            {/* </Header> */}
            <Layout>
              <Sider width={250} className="site-layout-background" style={{ backgroundColor: "#001529", paddingTop: 20 }}>
                <Nav navView={this.state.navSide} />
              </Sider>
              <Content
                className="site-layout-background"
                style={{
                  padding: 24,
                  paddingTop: 60,
                  margin: 0,
                  minHeight: 280,
                }}
              >
                {/* <Navigation />
                 */}
                <Switch>
                  <Route exact path="/" component={() => {
                    return <Home changeNavBar={(status) => { this.setState({ navSide: status }) }}
                    />
                  }} Title="/" />
                  <Route exact path="/Projects" component={Projects} Title="/Projects" />
                  <Route exact path="/ActiveProjects" component={ActiveProjects} Title="/ActiveProjects" />
                  <Route exact path="/CompletedProjects" component={CompletedProjects} Title="/CompletedProjects" />
                  <Route exact path="/AddProject" component={AddProject} Title="/AddProject" />
                  <Route exact path="/AddSupport" component={AddSupport} Title="/AddSupport" />
                  <Route exact path="/EditProject/:id" component={EditProject} Title="/EditProject" />
                  <Route exact path="/TodayProjects" component={TodayProjects} Title="/TodayProjects" />
                  <Route exact path="/Users" component={Users} Title="/Users" />
                  <Route exact path="/AssignUsersSupport" component={AssignUsersSupport} Title="/AssignUsersSupport" />
                  <Route exact path="/Support" component={Support} Title="/Support" />
                  <Route exact path="/UsersAssign" component={UsersAssign} Title="/UsersAssign" />
                  <Route exact path="/Upload" component={Upload} Title="/Upload" />
                  <Route exact path="/UsersAssignDetails" component={UsersAssignDetails} Title="/UsersAssignDetails" />
                  <Route exact path="/HistoryProject/:id/:name/:index" component={HistoryProject} Title="/HistoryProject" />
                  <Route exact path="/HistorySupport/:id" component={HistorySupport} Title="/HistorySupport" />
                  <Route exact path="/EditAssign" component={EditAssign} Title="/EditAssign" />
                  <Route exact path="/HistoryUser/:id/:name" component={HistoryUser} Title="/HistoryUser" />
                  <Route exact path="/Tasks" component={Tasks} Title="/Tasks" />
                  <Route exact path="/Activity" component={Activity} Title="/Activity" />
                  <Route exact path="/TeamLeadReport" component={TeamLeadReport} Title="/TeamLeadReport" />
                  <Route exact path="/Report" component={Report} Title="/Report" />
                  <Route exact path="/UploadSalesStage" component={UploadSalesStage} Title="/UploadSalesStage" />
                  <Route exact path="/UploadProjectDetails" component={UploadProjects} Title="/UploadProjectDetails" />
                  <Route exact path="/Role" component={Role} Title="/Role" />
                  <Route exact path="/NonBoundaryActivity" component={NonBoundaryActivity} Title="/NonBoundaryActivity" />
                  <Route exact path="/Teams" component={Team} Title="/Teams" />
                </Switch>
              </Content>
            </Layout>
          </Layout>
        </Router>
      </Provider>
    );
  }
}

export default App;
