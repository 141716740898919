import { Skeleton } from 'antd';
import React, { Component } from 'react';
import "../../assets/css/RecentActivities.scss"
import TableSkelton from '../Loader/TableSkelton';
class RecentActivities extends Component {
    render() {
        return (
            <div className="RecentActivities" style={{ padding: 10 }}>
                <h6 >My Recent Activities</h6>
                <div className="RecentActivities_card scroll">
                    {
                        this.props.activity ? this.props.activity.sort((prevValue, nextValue) => { return new Date(nextValue.date) - new Date(prevValue.date) }).slice(0, 15).map((value, index) => {
                            return <div className="RecentActivities_card_tab">
                                <div className="RecentActivities_card_tab_content">
                                    <div className="RecentActivities_card_tab_Title">
                                        {value.projectName}
                                    </div>
                                    <div className="RecentActivities_card_tab_date">
                                        {new Date(value.date).getMonth() + 1 + "/" + new Date(value.date).getDate() + "/" + new Date(value.date).getFullYear()}
                                    </div>
                                    <div className="RecentActivities_card_tab_tap">
                                        <h6 className="RecentActivities_card_tab_tap_title">Total Time</h6>
                                        <h6>{value.userTime.toFixed(0)}H {value.userTime % 1 * 60}min</h6>
                                    </div>
                                    <div className="RecentActivities_card_tab_tap">
                                        <h6 className="RecentActivities_card_tab_tap_title">Overall Spent Time</h6>
                                        <h6>{value.currentTime == 0 ? "-" : (value.currentTime.toFixed(0) + "H " + value.currentTime % 1 * 60 + "min")}</h6>
                                    </div>
                                </div>
                            </div>

                        }) :
                            <TableSkelton rows={4} columns={3} numberOfRows={1} />
                    }
                </div>
            </div>
        );
    }
}

export default RecentActivities;