import React, { Component } from 'react';
import "../../assets/css/Activity/OverallActivities.scss"
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

class OverallActivities extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeIndex: []
        }
    }

    onPieEnter = (data, index) => {
        this.setState({
            activeIndex: index,
        });
    };

    render() {
        let colorsLit = ["#dda900", "#ed1c24"]
        return (
            <div className="OverallActivities">
                <h7>Recent Activity</h7>
                <div className="OverallActivities_card">
                    {this.props.activity &&
                        <ResponsiveContainer>
                            <AreaChart

                                data={this.props.activity.sort((prevValue, nextValue) => { return new Date(nextValue.date) - new Date(prevValue.date) }).slice(0, 14)}
                                margin={{
                                    top: 10, right: 30, left: 0, bottom: 0,
                                }}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="70%" stopColor="#ed1c24" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#f4f3ef" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" />
                                <YAxis />
                                <Tooltip formatter={(value, name) => {
                                    return `${value}`
                                }} />
                                <Area type="monotone" dataKey="userTime" name="Time Spent" stroke="#ed1c24" fill="url(#colorUv)" />
                                {/* <Area type="monotone" dataKey="currentTime" stroke="#ed1c24" fill="url(#colorUv)"  /> */}
                            </AreaChart>
                        </ResponsiveContainer>
                    }
                </div>
            </div>
        );
    }
}

export default OverallActivities;