import { message , Modal } from 'antd';
import React, { Component } from 'react';
import EventCard from "./EventGraph"

class DefaultCard extends Component {
    render() {
        const error = function() {
            Modal.warning({
              title: 'Warning',
              content: "Today's time limit has been exceeded."
            });
          };
        return (<div className="task_event_cardTab_main">
            <div className="task_event_reduce" onClick={() => { (this.props.event.projectAssignId != 0 && this.props.event.time > 0 ? this.props.reduceTime(this.props.event.projectAssignId, 1) : message.error("Minimum Limit Reach")) }}>
                <div className="reduce_button_event">
                    <div className="reduce_button_event_content">
                        -
                        </div>
                </div>
            </div>
            <div className="task_event_cardTab">
                <div className="task_event_graph">
                    <EventCard
                        event={this.props.event}
                        addTime={(id, defaultProjectId, value) => {
                            if (this.props.totalTime <= 23) {
                                this.props.addTime(id, defaultProjectId, value)
                            } else {
                               error();
                            }
                        }}
                        time={this.props.event.time}
                        data={[{ name: "time", stroke: "#888888", fill: "#f12c06", value: (this.props.event.time / 24) * 100 }, { name: "time", stroke: "#888888", fill: "#888888", value: ((24 - this.props.event.time) / 24) * 100 }]} />
                </div>
                <div className="task_event_Name">
                    <p className="task_event_eventName">{this.props.event.projectName}</p>
                </div>

            </div>

        </div>
        );
    }
}

export default DefaultCard;