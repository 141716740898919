import React, { Component } from 'react';
import "./projectCard.scss"
import { Link } from "react-router-dom"

const ProjectCard = ({ support, index, stateChange }) => {
    return (
        <div className="support_card">
            <div className="project_card_image_tab">
                {support.status == 1 &&
                    <Link to={`/EditProject/${support.id}`} style={{ border: "none", appearance: "none", textDecoration: "unset" }}>
                        <div className="project_card_image_tab_edit">
                            <icon className="fa fa-edit" />
                        </div>
                    </Link>
                }
                <img alt="" src={require("./projectCard.png")} className="project_card_image" />
            </div>
            <div className="project_card_log_tab">
                <div className="project_card_log">
                    <img alt="" src={(support.image != "https://protimestorage.blob.core.windows.net/protimer/" ? support.image : require("../../assets/images/temp_logo.png"))} className="project_image" />
                </div>
                <div
                    onClick={() => {
                        if (!support.isActive) {
                            var confirmation = window.confirm("Are you sure to Activate Project '" + support.supportName + "'")
                            if (confirmation) {
                                stateChange(support.id, 1)
                            }
                        }
                    }
                    }
                    className={(support.isActive ? "project_card_status_active" : "project_card_status_inactive")}>
                    <span className="project_card_status_word">Active</span>
                </div>
                <div
                    onClick={() => {
                        if (support.isActive) {
                            var confirmation = window.confirm("Are you sure to Complete Project '" + support.supportName + "'")
                            if (confirmation) {
                                stateChange(support.id, 0)
                            }
                        }
                    }
                    }
                    className={(support.isActive ? "project_card_status_inactive" : "project_card_status_completed")}>
                    <span className="project_card_status_word">Completed</span>
                </div>
            </div>
            <div className="project_card_header_tab">
                <h6 className="project_card_header_header">{support.supportName}</h6>
                <h7 className="project_card_header_decription">{support.message}</h7>
            </div>
            <div className="row">
                {/* <div className="col-md-6 project_card_values_content" style={{ padding: 0 }}>
                    <div className="btn btn_project_card" onClick={() => setUserModalView()}>
                        View Users
                    </div>
                </div> */}
                <div className="col-md-12 project_card_values_content" style={{ padding: 0 }}>
                    <Link to={`/HistorySupport/${support.id}`} style={{ border: "none", appearance: "none", textDecoration: "unset" }}>
                        <div className="btn btn_project_card">
                            View Details
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default ProjectCard;