import React, { useEffect } from 'react';
import Home from "../../store/components/Home"
import Projects from "../../store/components/Projects/Projects"
import ActiveProjects from "../../store/components/Projects/ActiveProjects"
import CompletedProjects from "../../store/components/Projects/CompletedProjects"
import NonBoundaryActivity from "../../store/components/NonBoundaryActivity"
import AddProject from "../../store/components/Projects/AddProject"
import EditProject from "../../store/components/Projects/EditProject"
import HistoryProject from "../../store/components/Projects/HistoryProject"
import TodayProjects from "../../store/components/Projects/TodayProjects"
import Users from "../../store/components/Users/Users"
import Upload from "../../store/components/Users/Upload"
import UsersAssignDetails from "../../store/components/Users/UsersAssignDetails"
import EditAssign from "../../store/components/Users/EditAssign"
import HistoryUser from "../../store/components/Users/HistoryUser"
import UsersAssign from "../../store/components/Users/UsersAssign"
import Role from "../../store/components/Role"
import Team from "../../store/components/Team"
import { Router, Switch, Route } from "react-router-dom";
import Activity from "../../store/components/Activity/Activity"
import * as Actions from "../../store/actions/usersActions"
import Tasks from "../../store/components/Tasks/Tasks"
import { connect } from "react-redux"
import AuthContext from '../../services/Auth'

const Navigation = ({ navigation, isTeamLead, isAdmin }) => {


    return (
        <Switch>
            <Route exact path="/" component={() => {
                return <Home changeNavBar={(status) => { this.setState({ navSide: status }) }}
                />
            }} Title="/" />
            <Route exact path="/Projects" component={Projects} Title="/Projects" />
            <Route exact path="/ActiveProjects" component={ActiveProjects} Title="/ActiveProjects" />
            <Route exact path="/CompletedProjects" component={CompletedProjects} Title="/CompletedProjects" />
            {navigation && (navigation.isAdminSuccess || navigation.isProjectManagerSuccess) &&
                <Route exact path="/AddProject" component={AddProject} Title="/AddProject" />
            }
            <Route exact path="/EditProject/:id" component={EditProject} Title="/EditProject" />
            <Route exact path="/TodayProjects" component={TodayProjects} Title="/TodayProjects" />
            <Route exact path="/Users" component={Users} Title="/Users" />
            <Route exact path="/UsersAssign" component={UsersAssign} Title="/UsersAssign" />
            <Route exact path="/Upload" component={Upload} Title="/Upload" />
            <Route exact path="/UsersAssignDetails" component={UsersAssignDetails} Title="/UsersAssignDetails" />
            <Route exact path="/HistoryProject/:id/:name/:index" component={HistoryProject} Title="/HistoryProject" />
            <Route exact path="/EditAssign" component={EditAssign} Title="/EditAssign" />
            <Route exact path="/HistoryUser/:id/:name" component={HistoryUser} Title="/HistoryUser" />
            <Route exact path="/Tasks" component={Tasks} Title="/Tasks" />
            <Route exact path="/Activity" component={Activity} Title="/Activity" />
            {navigation &&
                (navigation.isAdminSuccess || navigation.isTeamLeadSuccess) &&
                <>
                    {navigation.isAdminSuccess &&
                        <Route exact path="/Role" component={Role} Title="/Role" />
                    }
                    <Route exact path="/NonBoundaryActivity" component={NonBoundaryActivity} Title="/NonBoundaryActivity" />
                    <Route exact path="/Teams" component={Team} Title="/Teams" />

                </>
            }
        </Switch>
    );
};

const mapStateToProps = (state) => {
    return {
        navigation: state.usersReducers
    }
}

export default connect(mapStateToProps, Actions)(Navigation);