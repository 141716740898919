import React, { Component } from 'react';
import "../../assets/css/ProjectsActivity.scss"

class ProjectsActivity extends Component {
    render() {
        return (
            <div className="ProjectsActivity">
                <h6> Active Projects/Support contracts</h6>
                <div className="ProjectsActivity_content scroll">
                    {(this.props.projectList && this.props.projectList.length != 0 ? this.props.projectList.map((value, index) => {
                        return <div className="ProjectsActivity_card_tab_projectContent">
                            <div className="ProjectsActivity_card_tab_Title">
                                {value.name}
                            </div>
                            <div className="ProjectsActivity_card_tab_content" >
                                <div className="ProjectsActivity_card_tab_tap">
                                    <h6 className="ProjectsActivity_card_tab_tap_title">Total Time</h6>
                                    <h6>{value.currentHours}H</h6>
                                </div>
                                <div className="ProjectsActivity_card_tab_tap">
                                    <h6 className="ProjectsActivity_card_tab_tap_title">Overall Expected Time</h6>
                                    <h6>{value.expectedHours}H</h6>
                                </div>
                            </div>
                        </div>
                    }) : <p>Not Available</p>)}
                </div>
            </div>
        );
    }
}

export default ProjectsActivity;