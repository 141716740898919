import React, { Component } from 'react';
import { Pie, PieChart, Cell, Tooltip } from "recharts"
class EventGraph extends Component {
    render() {
        const RADIAN = Math.PI / 180;

        const renderCustomizedLabelEvent = ({
            cx, cy, midAngle, innerRadius, outerRadius, percent, index,
        }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * -0.01;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (<>
                <text
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        this.props.addTime(this.props.event.projectAssignId, this.props.event.projectId, 1);
                    }}
                    x={x - 9} fontSize={16} y={y - 7} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ textAlign: "center", cursor: "pointer" }}>
                    {`${this.props.time}H`}
                </text>
                <text x={x - 11} fontSize={12} y={y + 7} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ textAlign: "center", cursor: "pointer" }}>
                    {`${(this.props.time % 1) * 60 + "min"}`}
                </text>
                {/* <text x={x - 20} y={y + 5} fill={(this.props.expectedHours > this.props.currentHours ? "#6e93ec" : "#dc3f3f")} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ textAlign: "center" }}>
                    {`${(this.props.currentHours / this.props.expectedHours * 100).toFixed(2)}%`}
                </text>
                <text x={x - 20} y={y + 30} fill={(this.props.userTime > this.props.currentHours ? '#6fec91' : 'orange')} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ textAlign: "center" }}>
                    {`${(this.props.currentHours / this.props.userTime * 100).toFixed(2)}%`}
                </text> */}
            </>
            );
        };
        return (
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
                <PieChart width={90} height={100}>
                    <Pie
                        data={this.props.data}
                        cx={40}
                        cy={40}
                        labelLine={false}
                        //label={this.props.data}
                        outerRadius={40}
                        innerRadius={35}
                        dataKey="value">
                    </Pie>
                    <Pie
                        fill="#888888"
                        outerRadius={25}
                        data={[{ value: 1 }]}
                        dataKey="value"
                        isAnimationActive={false}
                        style={{ cursor: "pointer" }}
                        cx={40}
                        cy={40}
                        stroke="#888888"
                        label={renderCustomizedLabelEvent}
                        onClick={() => {
                            this.props.addTime(this.props.event.projectAssignId, this.props.event.projectId, 1);
                        }}
                    ></Pie>
                </PieChart>
                <div onClick={() => {
                    this.props.addTime(this.props.event.projectAssignId, this.props.event.projectId, 1);
                }} style={{ width: 50, height: 50, position: "absolute", borderRadius: 70, cursor: "pointer" }}>
                </div>
            </div>
        );
    }
}

export default EventGraph;